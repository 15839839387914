export default {
    init() {
        const forms = document.querySelectorAll('.custom-form') as NodeListOf<HTMLElement>;

        if (forms.length === 0) return;

        forms.forEach((form) => {
            const currentFormElement = form.querySelector('form');

            if (!currentFormElement) return;

            const formHandle = currentFormElement.querySelector('input[name="handle"]') as HTMLInputElement;

            if (!formHandle) return;

            //Find the CSRF token hidden input, so we can replace it
            let csrfInput = currentFormElement.querySelector('input[name="CRAFT_CSRF_TOKEN"]') as HTMLInputElement;

            if (!csrfInput) return;

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch('/actions/formie/forms/refresh-tokens?form=' + formHandle.value)
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    csrfInput = result.csrf.input;

                    if (!form) return;

                    const formInputField = form.querySelector('input[name="' + result.csrf.param + '"]') as HTMLInputElement;

                    if (!formInputField) return;

                    formInputField.value = result.csrf.token;
                });

            if (form) {
                ////// Input
                const input = form.querySelectorAll('input[type="text"]') as NodeListOf<HTMLInputElement>;
                if (input) {
                    input.forEach((input: HTMLInputElement) => {
                        if (input) {
                            const inputParent = input.parentElement?.previousElementSibling;
                            if (input.value.length > 0) {
                                inputParent?.classList.add('float');
                            }

                            input.addEventListener('focus', () => {
                                inputParent?.classList.add('float');
                                inputParent?.classList.add('selected');
                                input.classList.add('selected');
                            });

                            input.addEventListener('blur', () => {
                                if (input.value.length == 0) {
                                    inputParent?.classList.remove('float');
                                }
                                inputParent?.classList.remove('selected');
                                input.classList.remove('selected');
                            });
                        }
                    });
                }

                ////// Mail
                const mail = form.querySelectorAll('input[type="email"]') as NodeListOf<HTMLInputElement>;

                mail.forEach((mail) => {
                    const mailParent = mail.parentElement?.previousElementSibling;

                    if (mail.value.length > 0) {
                        mailParent?.classList.add('float');
                    }

                    mail.addEventListener('focus', () => {
                        mailParent?.classList.add('float');
                        mailParent?.classList.add('selected');
                        mail.classList.add('selected');
                    });

                    mail.addEventListener('blur', () => {
                        if (mail.value.length == 0) {
                            mailParent?.classList.remove('float');
                        }
                        mailParent?.classList.remove('selected');
                        mail.classList.remove('selected');
                    });
                });

                ////// Textarea
                // const textarea = form.querySelectorAll('textarea') as NodeListOf<HTMLTextAreaElement>;

                // textarea.forEach((textarea) => {
                //     const textareaParent = textarea.parentElement?.previousElementSibling;

                //     if (textarea.value.length > 0) {
                //         textareaParent?.classList.add('float');
                //     }

                //     textarea.addEventListener('focus', () => {
                //         textareaParent?.classList.add('float');
                //         textareaParent?.classList.add('selected');
                //         textarea.classList.add('selected');
                //     });

                //     textarea.addEventListener('blur', () => {
                //         if (textarea.value.length == 0) {
                //             textareaParent?.classList.remove('float');
                //         }
                //         textareaParent?.classList.remove('selected');
                //         textarea.classList.remove('selected');
                //     });
                // });
            }

            const pageSteps = form.querySelector('.custom-form-page-step') as HTMLElement;

            if (pageSteps) {
                const formPages = currentFormElement.querySelectorAll('.fui-page') as NodeListOf<HTMLElement>;
                let currentPage = 1;

                formPages.forEach((page) => {
                    if (!page.hasAttribute('data-fui-page-hidden')) {
                        const initialCurrentPage = page.dataset.index ? +page.dataset.index + 1 : 1;
                        currentPage = initialCurrentPage;

                        pageSteps.innerText = currentPage.toString();
                    }
                });

                currentFormElement.addEventListener('onAfterFormieSubmit', (e: any) => {
                    currentPage = e.detail.nextPageIndex + 1;

                    pageSteps.innerText = currentPage.toString();

                    if (!e.detail.nextPageId) {
                        const formSuccess = document.querySelector('.custom-form-success-screen') as HTMLElement;

                        if (formSuccess) {
                            formSuccess.classList.add('top-0');
                            formSuccess.classList.remove('opacity-0', 'pointer-events-none', 'top-full');
                        }
                    }
                });
            }
        });
    }
};

// Cookie banner via klaor
import * as Klaro from 'klaro';

// The cookie banner is now fully configured via Global Options
// Please DO NOT add cookies / tracking scripts in this file!

// See https://klaro.org/docs/integration/annotated-configuration for all
// configuration options, especially translation. Klaro does not inject
// the tracking scripts, these are added via cookieBannerScripts.twig.
// Klaro triggers the injection via data-* attributes. Klaro also clears
// cookies based on regular expressions if users change their settings.

declare const cookieBannerDataJsonString: string; // global var

export default {
    init() {
        // Retrieve data / settings from JSON (global variable)
        if (typeof cookieBannerDataJsonString === 'undefined') {
            // console.error('cookieBannerDataString not defined.');
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cookieBannerData: any = {};
        try {
            cookieBannerData = JSON.parse(cookieBannerDataJsonString);
        } catch (e) {
            /*console.error('Failed to parse JSON for cookie banner data', {
                e,
                cookieBannerDataJsonString
            });*/
            // e.g. expected output: SyntaxError: Unexpected token o in JSON at position 1
            return;
        }

        // console.log({ cookieBannerData }, '- received from twig');

        // Domain detection (klaro currently needs exact cookie domain for deletion,
        // since some cookies like _ga set a domain with "." in front or use base domain
        // there were problems. In order to avoid this, we go through these variants:
        const domainVariations: string[] = [
            // a: www.maybe-with-subdomain.example.org
            location.hostname,
            // b: example.org (get base domain)
            location.hostname.split('.').slice(-2).join('.'),
            // c: .example.org (dot prefix)
            `.${location.hostname}`
        ];

        // Transform categories and services
        // (klaro has a different approach, services are added as list,
        // categories are defined inside these services
        // [services:{purposes: [category1, ]}])

        // we currently use 'essential' and 'externalmedia' as default groups in source code,
        // not configurable via global fields (hardcoded)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const services: any = [
            // required (essential) cookies
            {
                name: 'craft-cms',
                title: 'Craft CMS',
                default: true,
                purposes: ['essential'],
                cookies: [['CraftSessionId'], ['*_identity'], ['*_username'], ['CRAFT_CSRF_TOKEN']],
                required: true
            },
            {
                name: 'klaro',
                title: 'Klaro!',
                purposes: ['essential'],
                required: true
            },
            // Defaults for contextual cookies like youtube, twitter, ...
            // https://klaro.org/docs/tutorials/contextual_consent
            {
                purposes: ['externalmedia'],
                name: 'youtube',
                title: 'YouTube',
                cookies: [['YSC'], ['VISITOR_INFO1_LIVE']]
            }
        ];
        // translation array for purposes (categories), translation for "essential" and "externalmedia"
        // is provided via twig, but if user creates these categories to extend cookies the translation
        // of the category in services is used
        const purposesTranslations = {
            ...cookieBannerData.translations.purposes
        };
        // transform categories
        for (const category of cookieBannerData.categoriesAndServices) {
            // console.log({ category });
            // categoryTitle is a translatable field in craft
            purposesTranslations[category.uniqueName] = {
                title: category.categoryTitle,
                description: '' // leave descriptions blank for now
            };

            // loop through regexes (table) and generate cookies array
            /* this will generate something like this, just looking for all occurences of _ga in name
            in all domain variations:
                    [/_ga/i, '/', 'starter-project.live'],
                    [/_ga/i, '/', '.starter-project.live'],
                    [/_ga/i, '/', 'subdomain.starter-project.live'],
                    ...
            */
            for (const service of category.services) {
                const cookies = [];
                for (const cookieNameColumn of service.cookieNames) {
                    if (cookieNameColumn.cookieName == '') continue;
                    const regex = new RegExp(`${cookieNameColumn.cookieName}`, 'i');
                    // use the regular expression without path and domain
                    cookies.push([regex]);

                    for (const domainVariation of domainVariations) {
                        cookies.push([
                            regex, // regex or title
                            '/', // path
                            domainVariation // domain
                        ]);
                    }
                }

                // add services to list of services
                services.push({
                    name: service.uniqueName,
                    title: service.serviceTitle,
                    purposes: [category.uniqueName],
                    cookies: cookies,
                    default: service.default,
                    required: service.required,
                    onlyOnce: service.onlyOnce
                });
            }
        }

        // setup translation config / overrides, klaro needs {de/en:{..}}, there is no option
        // to just set it for the current language. klaro will retrieve it from <html lang="">
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let translationOverrides: any = {};
        // add lang 'de' or 'en', etc (beware: klaro uses an object, not array)
        translationOverrides[cookieBannerData.translations.lang] = cookieBannerData.translations;
        // purpose translations are create above dynamically from category title (translatable field in craft)
        translationOverrides[cookieBannerData.translations.lang].purposes = purposesTranslations;
        // remove null values from translations, these can be given by twig and cause "translation not found"
        translationOverrides = this.removeNullValues(translationOverrides);
        // Our final configuration
        const config = {
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: cookieBannerData.generalSettings?.showAsModal, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: translationOverrides,
            services: services
        };

        //console.log({ config }, '- the generated klaro config');

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);
    },

    // safeguard: remove null from provided translations (nested object)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeNullValues(obj: Record<string, any>): Record<string, any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
            if (value !== null) {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    const filteredNestedObj = this.removeNullValues(value);
                    if (Object.keys(filteredNestedObj).length > 0) {
                        acc[key] = filteredNestedObj;
                    }
                } else {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});
    }
};

//

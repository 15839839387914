export default {
    init(btnScrollUp: HTMLElement) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 500) {
                btnScrollUp.classList.add('active');
            } else {
                btnScrollUp.classList.remove('active');
            }
        });

        btnScrollUp.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }
};

// https://masonry.desandro.com/
import Masonry from 'masonry-layout';

export default {
    init(grids: NodeListOf<Element>) {
        grids.forEach((grid: Element) => {
            const masonry = new Masonry(grid, {
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true
            });
        });
    }
};

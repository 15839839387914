export default {
    init(dropdown: Element) {
        if (!dropdown) return;
        const dropdownBtn = dropdown.querySelector('button') as HTMLElement;
        const list = dropdown.querySelector('.options-dropdown-btn-list') as HTMLElement;

        if (!dropdownBtn || !list) return;

        dropdownBtn.addEventListener('click', () => {
            list.classList.toggle('hidden');
        });
    }
};

export default {
    init(faqPage: HTMLElement) {
        const sections = faqPage.querySelectorAll('.faq-block-section');

        if (!sections) return;

        sections.forEach((section) => {
            const faqHeads = section.querySelectorAll('.faq-head') as NodeListOf<HTMLElement>;
            const faqBodies = section.querySelectorAll('.faq-body') as NodeListOf<HTMLElement>;

            if (!faqHeads) return;

            faqHeads.forEach((faqHead) => {
                faqHead.addEventListener('click', () => {
                    const faqBody = faqHead.nextElementSibling as HTMLElement;

                    if (!faqBody) return;

                    // close all faq blocks
                    if (faqBodies.length) {
                        faqBodies.forEach((fBody: HTMLElement) => {
                            fBody.classList.add('animate-body');
                            fBody.setAttribute('style', 'max-height:0;');
                        });
                    }

                    // set clicked faq active
                    if (faqHead.classList.contains('active')) {
                        if (faqHeads.length) {
                            faqHeads.forEach((fHead: HTMLElement) => {
                                fHead.classList.remove('active');
                            });
                        }

                        if (faqBody) {
                            faqBody.setAttribute('style', 'max-height:0;');
                        }
                    } else {
                        if (faqHeads.length) {
                            faqHeads.forEach((fHead: HTMLElement) => {
                                fHead.classList.remove('active');
                            });
                        }

                        faqHead.classList.add('active');
                        if (faqBody) {
                            faqBody.style.maxHeight = faqBody.scrollHeight + 'px';
                        }
                    }
                });
            });
        });

        // Open first faq question in first faq block
        const firstActiveFaqHead = sections[0].querySelector('.faq-head') as HTMLElement;

        if (firstActiveFaqHead) {
            firstActiveFaqHead.classList.add('active');
            const firstActiveFaqBody = firstActiveFaqHead.nextElementSibling as HTMLElement;

            if (!firstActiveFaqBody) return;

            firstActiveFaqBody.style.maxHeight = firstActiveFaqBody.scrollHeight + 'px';
        }
    }
};

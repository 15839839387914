export default {
    init(section: HTMLElement) {
        const accordionBlockContainer = section.querySelector('.accordion-block-container');

        if (!accordionBlockContainer) return;

        // sections.forEach((section) => {
        const accordionHeads = section.querySelectorAll('.accordion-head') as NodeListOf<HTMLElement>;
        const accordionBodies = section.querySelectorAll('.accordion-body') as NodeListOf<HTMLElement>;

        if (!accordionHeads) return;

        accordionHeads.forEach((accordionHead) => {
            accordionHead.addEventListener('click', () => {
                const accordionBody = accordionHead.nextElementSibling as HTMLElement;

                if (!accordionBody) return;

                // close all accordion blocks
                if (accordionBodies.length) {
                    accordionBodies.forEach((fBody: HTMLElement) => {
                        fBody.classList.add('animate-body');
                        fBody.setAttribute('style', 'max-height:0;');
                    });
                }

                // set clicked accordion active
                if (accordionHead.classList.contains('active')) {
                    if (accordionHeads.length) {
                        accordionHeads.forEach((fHead: HTMLElement) => {
                            fHead.classList.remove('active');
                        });
                    }

                    if (accordionBody) {
                        accordionBody.setAttribute('style', 'max-height:0;');
                    }
                } else {
                    if (accordionHeads.length) {
                        accordionHeads.forEach((fHead: HTMLElement) => {
                            fHead.classList.remove('active');
                        });
                    }

                    accordionHead.classList.add('active');
                    if (accordionBody) {
                        accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                    }
                }
            });
        });
        // });

        // Open first accordion question in first accordion block
        const firstActiveaccordionHead = section.querySelector('.accordion-head') as HTMLElement;

        if (firstActiveaccordionHead) {
            firstActiveaccordionHead.classList.add('active');
            const firstActiveaccordionBody = firstActiveaccordionHead.nextElementSibling as HTMLElement;

            if (!firstActiveaccordionBody) return;

            firstActiveaccordionBody.style.maxHeight = firstActiveaccordionBody.scrollHeight + 'px';
        }
    }
};

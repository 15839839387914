import utils from './utils';

export default {
    init(heroElement: HTMLElement) {
        const mdBreakpoint = utils.getMQValue('md');

        if (window.matchMedia('(max-width: ' + mdBreakpoint + 'px)').matches) {
            const flipCard = heroElement.querySelector('.flip-card') as HTMLElement;

            if (flipCard) {
                flipCard.addEventListener('click', () => {
                    flipCard.classList.toggle('is-flipped');
                });
            }
        }
    }
};

import anime from 'animejs';
import utils from './utils';

export default {
    init(slidingBanner: Element, stopOnHover = true) {
        if (!slidingBanner) return;
        let slidingBannerContainer = slidingBanner.querySelector('.sliding-banner-container') as HTMLElement;

        let bannerBlueprintContainer = slidingBannerContainer?.cloneNode(true) as HTMLElement;

        if (!slidingBannerContainer || !bannerBlueprintContainer) return;

        // comment
        const initializeSlidingBanner = (replaceExistingBanner = false) => {
            if (replaceExistingBanner) {
                slidingBannerContainer.remove();
                slidingBanner.append(bannerBlueprintContainer);
                slidingBannerContainer = bannerBlueprintContainer;
                bannerBlueprintContainer = slidingBannerContainer.cloneNode(true) as HTMLElement;
            }

            const slidingBannerLoop = slidingBanner.querySelector('.sliding-banner-loop') as HTMLElement;

            // gets repeated to fill the viewport
            const slidingBannerLoopSegment = slidingBannerLoop?.querySelector('.sliding-banner-loop-segment') as HTMLElement;

            const viewportWidth = window.innerWidth;

            if (!slidingBannerLoopSegment || !viewportWidth) return;
            const repetitions = Math.ceil(viewportWidth / slidingBannerLoopSegment.offsetWidth);

            // Repeat the slide content to fill the viewport
            for (let i = 0; i < repetitions; i++) {
                const clone = slidingBannerLoopSegment.cloneNode(true) as HTMLElement;

                slidingBannerLoop.appendChild(clone);
            }

            // Add a clone of the sliding loop to use for the animation
            const clone = slidingBannerLoop.cloneNode(true) as HTMLElement;
            if (clone) {
                slidingBannerContainer.appendChild(clone);
            }

            const allSlidingBannerLoops = slidingBanner.querySelectorAll('.sliding-banner-loop') as NodeListOf<HTMLElement>;
            if (!allSlidingBannerLoops) return;

            const twinSlidingBannerLoop = allSlidingBannerLoops[1];

            const slidingAnimation = anime({
                targets: slidingBannerContainer,
                translateX: [0, '-100%'],
                easing: 'linear',
                duration: 70000,
                delay: 0,
                loop: true,
                update: () => {
                    if (Math.ceil(twinSlidingBannerLoop.getBoundingClientRect().left) <= 0) {
                        slidingAnimation?.restart();
                    }
                }
            });

            if (stopOnHover) {
                slidingBannerContainer.addEventListener('mouseenter', () => {
                    if (slidingAnimation) slidingAnimation.pause();
                });

                slidingBannerContainer.addEventListener('mouseleave', () => {
                    if (slidingAnimation) slidingAnimation.play();
                });
            }
        };

        initializeSlidingBanner();

        let prevWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            if (window.innerWidth !== prevWidth) {
                prevWidth = window.innerWidth;
                utils.debounce(() => {
                    initializeSlidingBanner(true);
                });
            }
        });
    }
};

import lottie from 'lottie-web';

export default {
    init(keyvisual: HTMLElement) {
        lottie.loadAnimation({
            container: keyvisual, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/dist/animations/keyvisual.json' // the path to the animation json
        });
    }
};

import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import utils from './utils';

export default {
    init() {
        //Stops menu from animating on load
        if (document.body.classList.contains('menu-no-animation')) {
            document.body.classList.remove('menu-no-animation');
        }
        //////////// Navigation
        const headerMenu = document.querySelector('.header-menu') as HTMLElement;
        const hamburger = document.getElementById('hamburger');
        const menuItems = document.querySelector('.header-menu-overlay-links') as HTMLElement;

        if (headerMenu && hamburger && menuItems) {
            hamburger.addEventListener('click', () => {
                headerMenu.classList.toggle('open');
                hamburger.classList.toggle('close');

                if (headerMenu.classList.contains('open')) {
                    lock(menuItems);
                } else {
                    clearBodyLocks();
                }
            });

            const menuItemSub: NodeListOf<Element> = headerMenu.querySelectorAll('.menu-item-sub');

            if (menuItemSub.length) {
                menuItemSub.forEach((item) => {
                    const button: HTMLButtonElement | null = item.querySelector('button');

                    if (button) {
                        button.addEventListener('click', () => {
                            const activeMenuItemSub = headerMenu.querySelector('.menu-item-sub.active');

                            item.classList.toggle('active');

                            if (activeMenuItemSub) {
                                activeMenuItemSub.classList.remove('active');
                            }
                        });
                    }
                });
            }
        }

        let lastScrollTop = 0;
        const headerWrapper = document.querySelector('.header-wrapper') as HTMLElement;
        // add scroll styling for header

        if (!headerWrapper.classList.contains('header-no-scroll')) {
            window.onscroll = function () {
                const scrollTop = document.documentElement.scrollTop;

                if (scrollTop > 10) {
                    headerMenu.classList.remove('bg-background-general');
                    headerMenu.classList.add('bg-white');
                } else {
                    headerMenu.classList.add('bg-background-general');
                    headerMenu.classList.remove('bg-white');
                }

                if (scrollTop > lastScrollTop) {
                    headerMenu.classList.add('hide');
                } else if (scrollTop < lastScrollTop) {
                    headerMenu.classList.remove('hide');
                }
                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
            };
        }
    }
};

import './app.scss';
import 'lazysizes';
import 'aos/dist/aos.css';
import AOS from 'aos';
import swiper from './ts/swiper';
import accordion from './ts/accordion';
import banner from './ts/banner';
import optionsDropdown from './ts/options-dropdown';
import slider from './ts/card-slider-autoplay';
import header from './ts/header';
import heroFlipCard from './ts/heroFlipCard';
import form from './ts/form';
import postMasonry from './ts/post-masonry';
import cookieBanner from './ts/cookie-banner';
import youtubeVideo from './ts/youtube-video';
import faqAccordion from './ts/faq-accordion';
import scrollUpComponent from './ts/scroll-up';
import keyvisualComponent from './ts/keyvisual-animation';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        accordionSections.forEach(function (section) {
            accordion.init(section);
        });
    }

    const slidingBanners = document.querySelectorAll('.sliding-banner') as NodeListOf<HTMLElement>;
    if (slidingBanners.length) {
        slidingBanners.forEach((component) => {
            banner.init(component);
        });
    }

    const optionsDropdownBtn = document.querySelectorAll('.options-dropdown-btn') as NodeListOf<HTMLElement>;
    if (optionsDropdownBtn.length) {
        optionsDropdownBtn.forEach((component) => {
            optionsDropdown.init(component);
        });
    }

    const cardSliderAutoplay = document.querySelectorAll('.card-slider-autoplay') as NodeListOf<HTMLElement>;
    if (cardSliderAutoplay.length) {
        cardSliderAutoplay.forEach((container) => {
            slider.init(container);
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        header.init();
    }

    const heroElement = document.querySelector('.hero-flip-card') as HTMLElement;
    if (heroElement) {
        heroFlipCard.init(heroElement);
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
        });
    }

    const forms = document.querySelectorAll('.custom-form');
    if (forms.length) {
        form.init();
    }

    // no element request because element will be generated via js, only used once
    cookieBanner.init();

    const youtubeVideoContainers = document.querySelectorAll('.youtube-video-container') as NodeListOf<HTMLElement>;
    youtubeVideoContainers.forEach((container) => {
        youtubeVideo.init(container);
    });

    const faqPage = document.querySelector('.faq-page') as HTMLElement;
    if (faqPage) {
        faqAccordion.init(faqPage);
    }

    const btnScrollUp = document.querySelector('.btn-scroll-up') as HTMLElement;
    if (btnScrollUp) {
        scrollUpComponent.init(btnScrollUp);
    }

    const keyvisual = document.querySelector('.keyvisual-animation') as HTMLElement;
    if (keyvisual) {
        keyvisualComponent.init(keyvisual);
    }

    AOS.init();

    window.addEventListener('load', function () {
        const postsMasonry = document.querySelectorAll('.post-mansonry-grid');
        if (postsMasonry.length) {
            postMasonry.init(postsMasonry);
        }

        AOS.refresh();
    });
})();

// import * as Klaro from 'klaro';

export default {
    init(container: HTMLElement) {
        if (!container) return;

        const videoSections = document.querySelectorAll('.youtube-video-section');

        if (!videoSections) return;

        videoSections.forEach((section) => {
            const youtubeVideoContainers = section.querySelectorAll('.youtube-video-container') as NodeListOf<HTMLElement>;

            if (!youtubeVideoContainers.length) return;

            youtubeVideoContainers.forEach((youtubeContainer) => {
                const timeout = setTimeout(() => {
                    const cookieConsentDiv = youtubeContainer.querySelector('.context-notice') as HTMLElement;
                    const imageUrl = youtubeContainer.dataset.consentImage;

                    if (!cookieConsentDiv || !imageUrl) return;

                    cookieConsentDiv.style.backgroundImage =
                        // eslint-disable-next-line quotes
                        "url('" + imageUrl + "')";

                    clearTimeout(timeout);
                }, 300);
            });
        });
    }
};
